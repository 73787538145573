import { collection, query, onSnapshot, where, orderBy, doc, getDoc, getDocs, limit, startAfter, setDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { HEROKU_AGROLINK_URL, HEROKU_FOCUSED_ANALYST_EXTRACTRANSACTIONS_URL, HEROKU_FOCUSED_ANALYST_RELATIONSHIP_URL, HEROKU_FOCUSED_ANALYST_ACCEPTED_URL, HEROKU_AUTHORIZATION, HEROKU_INVOICE_SYNGENTA_TOTAL_URL, HEROKU_FOCUSED_ANALYST_CONSUPTION_URL, HEROKU_FOCUSED_ANALYST_CUSTOMERS_URL, HEROKU_GET_PERFORMANCE_URL, HEROKU_POST_COMPLETED_TRANSFER, HEROKU_GET_USER_BY_DOC, HEROKU_GET_BALANCE_TRANSFER_POINTS_URL, HEROKU_FAVORITE_ACCOUNTS, HEROKU_CREATE_POINT_GROUP, HEROKU_VALIDATE_USER, HEROKU_ADD_AND_INVITE, HEROKU_IS_IN_A_GROUP, HEROKU_GET_INVITES, HEROKU_ACCEPT_INVITE, HEROKU_SEARCH_CONTACT, HEROKU_GET_GROUP_DATA, HEROKU_INVITE_CONTACT, HEROKU_CHANGE_GROUP_NAME, HEROKU_LEAVE_GROUP, HEROKU_REMOVE_FROM_GROUP, HEROKU_END_GROUP, HEROKU_PROMOTE_TO_LEADER, HEROKU_REJECT_INVITE, HEROKU_GET_REGULATIONS, HEROKU_GET_RECORD_TYPE, HEROKU_GET_PROFILES_BY_USER_GROUP, HEROKU_GET_PERFORMANCE_SALES_TEAM_URL, HEROKU_GET_PERFORMANCE_SALES_TEAM_ANTICIPATION_URL, HEROKU_GET_DISTRIBUTORS_URL, HEROKU_GET_USER_INFO_BEFORE_LOGIN, HEROKU_REMOVE_FAVORITE_ACCOUNT, HEROKU_GROUP_HISTORY, HEROKU_CAMPAIGNS, HEROKU_CAMPAIGNS_ENROLLMENT, HEROKU_CAMPAIGNS_RESULTS, HEROKU_CAMPAIGNS_MY_NUMBERS, HEROKU_VERIFY_TARGET_GROUP, HEROKU_VERIFY_SWEEPSTAKE_IDS, HEROKU_GENERIC_INFO, HEROKU_GET_USER_REFERRAL_CODE, HEROKU_GET_CONSULTANT_BALANCE } from './URLconsts';

export default class DataHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;

    getConfigFile(`utils/DataHelper/config.js`).then((importedFile) => {
      this.customCfg = importedFile?.default;
    });
  }

  setUserHelper(userHelper) {
    this.userHelper = userHelper;
  }

  getList(snapshot) {
    let list = [];

    snapshot.forEach((doc) => {
      list.push(doc.data());
    })

    return list;
  }
  async snapChallenges(callback) {
    let challenges = [];
    let userProgresses = [];
    let challengesQ = query(collection(this.db, '/HowToEarn/Progress/records/'));
    let unsub = [];

    unsub['challenges'] = onSnapshot(challengesQ, (snapshot) => {
      challenges = this.getList(snapshot);
      let arrangedChallengeList = this.arrangeChallengeList(challenges, userProgresses);
      callback(arrangedChallengeList);
    })

    // Busca progressos do usuário
    let userFirebaseId = await this.userHelper.getUserFirebaseId();

    let userProgressesQ = query(collection(this.db, '/Users/' + userFirebaseId + '/CampaignProgress/'));
    unsub['user_progresses'] = onSnapshot(userProgressesQ, (snapshot) => {
      userProgresses = this.getList(snapshot);
      let arrangedChallengeList = this.arrangeChallengeList(challenges, userProgresses);
      callback(arrangedChallengeList);
    })

    return unsub;
  }
  arrangeChallengeList(challenges, userProgresses) {
    let objectOutput = {};

    challenges.forEach((challenge) => {
      if (!objectOutput[challenge.Id]) objectOutput[challenge.Id] = {};
      objectOutput[challenge.Id] = challenge;
    })

    userProgresses.forEach((userProgress) => {
      if (!objectOutput[userProgress.CampaignItem__c]) objectOutput[userProgress.CampaignItem__c] = {};
      objectOutput[userProgress.CampaignItem__c].userProgress = userProgress;
    })

    let arrayOutput = [];

    Object.entries(objectOutput).forEach((object) => {
      arrayOutput.push(object[1]);
    })

    return arrayOutput;
  }
  snapMilestones(callback) {
    let milestonesQ = query(collection(this.db, 'Milestone'), where('Season__c', '==', global.season), orderBy('Points__c'));

    let unsub = onSnapshot(milestonesQ, (snapshot) => {
      let milestones = this.getList(snapshot);
      callback(milestones);
    })

    return { milestones: unsub }
  }
  snapHowToEarns(callback) {
    let simplePointsQ = query(collection(this.db, '/HowToEarn/SimplePoint/records/'));
    let pointsPerDollarQ = query(collection(this.db, '/HowToEarn/PointPerDollar/records/'));
    let simplePoints = [];
    let pointsPerDollar = [];
    let userData;

    let unsub = {};

    unsub = this.userHelper.snapUser((data) => {
      userData = data;
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints, pointsPerDollar, userData);
      callback(arrangedHowToEarnList);
    });

    unsub['simple_points'] = onSnapshot(simplePointsQ, (snapshot) => {
      simplePoints = this.getList(snapshot);
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints, pointsPerDollar, userData);
      callback(arrangedHowToEarnList);
    })

    unsub['points_per_dollar'] = onSnapshot(pointsPerDollarQ, (snapshot) => {
      pointsPerDollar = this.getList(snapshot);
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints, pointsPerDollar, userData);
      callback(arrangedHowToEarnList);
    })

    return unsub;
  }
  async getReferFriendDoc() {
    let simplePointsQ = query(collection(this.db, '/HowToEarn/SimplePoint/records/'));
    let simplePointsDoc = await getDocs(simplePointsQ);
    let out = null;

    if (simplePointsDoc.docs.length) {
      simplePointsDoc.docs.every((doc) => {
        let docData = doc.data();

        if (docData?.HowToEarn__r?.AppScreen__c === 'ReferFriend') {
          out = docData.HowToEarn__r;
          return false;
        }

        return true;
      })
    }

    return out;
  }
  arrangeHowToEarnList(simplePoints, pointsPerDollar, userData) {
    let arrayOutput = [];

    let allPoints = [...simplePoints, ...pointsPerDollar];

    allPoints.forEach((point) => {
      if (point.HowToEarn__r) {
        const howToEarn = point.HowToEarn__r;

        const loyaltyVisibleString = howToEarn.LoyaltyVisible__c ? howToEarn.LoyaltyVisible__c : '';
        const loyaltyVisibleSet = loyaltyVisibleString.split(';');

        const milestoneVisibleString = howToEarn.MilestoneVisible__c ? howToEarn.MilestoneVisible__c : '';
        const milestoneVisibleSet = milestoneVisibleString.split(';');

        if (
          (loyaltyVisibleString.length === 0 || (userData && loyaltyVisibleSet.includes(userData.LoyaltyCategory__c)))
          // Removido no Syngenta
          // && (milestoneVisibleString.length === 0 || (userData && milestoneVisibleSet.includes(userData.MilestoneCategory__c)))
        ) {
          arrayOutput.push(point);
        }
      }
    })

    return arrayOutput;
  }
  async snapBanners(callback) {
    let bannersQ = query(collection(this.db, 'AdvertisingArea'));
    let userData = await this.userHelper.getUser();
    let hasUserLogged = !!userData;

    let unsub = onSnapshot(bannersQ, async (snapshot) => {
      let banners = this.getList(snapshot);

      if (banners?.length) {
        banners = banners.filter(banner => !hasUserLogged ? banner.Location__c === 'Home Deslogada' && banner?.Name.includes('Consultor') : banner.Location__c !== 'Home Deslogada' && banner?.Name.includes('Consultor'))

        // if has user logged
        if (!!hasUserLogged) {
          let newInfo = banners?.reduce((a, b) => {
            a.push(b.Id)
            return a
          }, []).join(';')
  
          const result = await this.getGenericInfo(
            "cgny2__AdvertisingArea__c",
            "cgny2__TargetGroup__c",
            "Id", 
            userData?.Id, 
            "Id", 
            newInfo, 
            null
          )

          if (result.length) {
            let validResult = result.filter(x => x.valid)
            if (validResult && validResult.length && banners?.filter(x => x.Id === validResult[0].value)) {
              banners = banners.reduce((group, banner) => {
                let check = validResult.filter(x => x.value === banner.Id)
                group = check?.length > 0 ? [...group, banner] : [...group]
                return group
              }, []);
            }
          }
        }
      }

      callback(banners);
    })

    return { banners: unsub }
  }
  snapRewards(callback) {
    let rewardsQ = query(collection(this.db, 'Reward'), orderBy('Points__c', 'desc'));

    let unsub = onSnapshot(rewardsQ, (snapshot) => {
      let rewards = this.getList(snapshot);
      callback(rewards);
    })

    return { rewards: unsub }
  }
  snapNews(callback) {
    let newsQ = query(collection(this.db, 'News'), orderBy('PublishDate__c', 'desc'));

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return { news: unsub }
  }
  snapYTVideos(callback) {
    let newsQ = query(collection(this.db, 'Youtube'), orderBy('PublishDate__c', 'desc'));

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return { ytvideos: unsub }
  }
  snapTrainings(callback) {
    let newsQ = query(collection(this.db, 'Training'), orderBy('PublishDate__c', 'desc'));

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return { trainings: unsub }
  }
  snapConfig(config, callback) {
    let configQ = query(collection(this.db, config.table));

    if (config.where?.length) {
      config.where.map(condition => {
        configQ = query(configQ, where(condition[0], condition[1], condition[2]))
      })
    }

    if (config.order?.length) {
      config.order.map(order => {
        configQ = query(configQ, orderBy(order[0], order[1]))
      })
    }

    let unsub = onSnapshot(configQ, (snapshot) => {
      let list = this.getList(snapshot);
      callback(list);
    })

    return unsub
  }
  async getNews(qty = 10, startAfterDoc = null) {
    let newsQ = query(collection(this.db, 'News'), orderBy('PublishDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }
  async getYTVideos(qty = 10, startAfterDoc = null) {
    let newsQ = query(collection(this.db, 'Youtube'), orderBy('PublishDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }
  async getTrainings(qty = 10, startAfterDoc = null) {
    let newsQ = query(collection(this.db, 'Training'), orderBy('PublishDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }

  async getSurveys(isQuiz, qty = 10, startAfterDoc = null) {

    let elQ = query(collection(this.db, isQuiz ? 'Quiz' : 'Survey'), orderBy('Order__c', 'asc'), limit(qty));
    if (startAfterDoc) {
      elQ = query(elQ, startAfter(startAfterDoc));
    }
    let elDoc = await getDocs(elQ);

    let data = [];
    let lastDoc = null;

    if (elDoc.docs.length) {
      data = elDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }

  async getArticle(articleId) {
    let firebaseId = articleId.substr(0, 15);
    let articleQ = doc(this.db, 'News', firebaseId);
    let articleDoc = await getDoc(articleQ);

    if (articleDoc?.exists()) {
      return articleDoc.data();
    }

    return false;
  }

  async getSurvey(surveyId, category = 'Survey') {
    if (!surveyId) return false;

    let firebaseId = surveyId.substr(0, 15);
    let surveyQ = doc(this.db, category, firebaseId);
    let surveyDoc = await getDoc(surveyQ);

    if (surveyDoc?.exists()) {
      return surveyDoc.data();
    }

    return false;
  }

  async getSurveyQuestions(surveyId, category = 'Survey') {
    if (!surveyId) return false;

    let firebaseId = surveyId.substr(0, 15);
    let questionsQ = query(collection(this.db, '/' + category + '/' + firebaseId + '/Question/'), orderBy('Order__c'));
    let questionsDoc = await getDocs(questionsQ);
    let data = [];

    if (questionsDoc.docs.length) {
      data = questionsDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }

  async getAppSettingText(textName, callback) {
    let textQ = doc(this.db, 'AppSetting', textName);
    let textDoc = await getDoc(textQ);

    if (textDoc?.exists() && textDoc?.data()['TextValue__c']) {
      callback(textDoc.data()['TextValue__c']);
    }
    else {
      callback('');
    }
  }

  getTermCompiledText(docData) {
    let text = '';

    let fields = [
      'acg_PdfUrl__c'
    ]

    if (!docData) {
      return text;
    }

    text = fields.reduce((acc, v) => acc + (docData[v] || ''), text)

    return text;
  }

  async getPrivacyText(callback) {
    let termsQ = query(
      collection(this.db, 'AcceptanceTermVersion')
      , where('acg_IsActive__c', '==', true)
      , where('acg_AcceptanceTerm__r.acg_IsPrivate__c', '==', true)
    );
    let termsDoc = await getDocs(termsQ);

    let text = 'Política de Privacidade indisponível'

    if (termsDoc.docs.length) {
      for (let i in termsDoc.docs) {
        let docData = termsDoc.docs[i].data();

        text = this.getTermCompiledText(docData);
      }
    }

    callback(text);
  }

  async getMemberCodeCampaigns() {
    let doc = query(collection(this.db, 'MemberCodeCampaigns'));
    let newsDoc = await getDocs(doc);

    let data = [];

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return { data };
  }

  async getTermsText(callback) {
    this.getAppSettingText('TermsOfService', callback);
  }

  async getHerokuDomainApi() {
    let urlQ = doc(this.db, 'AppSetting', 'HerokuApi');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      const { url, token } = JSON.parse(urlDoc.data().TextValue__c || '{}')

      return { url, token };
    }

    return false;

  }

  async getHerokuDomain() {
    // return 'http://localhost:3333'; // dev
    // return 'http://localhost:5000'; // uat
    // // return 'https://syngenta-homol-sync.herokuapp.com/'

    let urlQ = doc(this.db, 'AppSetting', 'HerokuUrl');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      return urlDoc.data().TextValue__c;
    }

    return false;
  }


  async getHerokuUrl(path, isRest = true) {
    if (!global.herokuDomain) {
      global.herokuDomain = await this.getHerokuDomain();
    }

    let url = '';

    if (global.herokuDomain) {
      url = global.herokuDomain

      if (isRest) {
        url += '/restHelper';
      }

      url += path;
    }

    return url;
  }

  async loadCurrentSeasonId() {
    let seasonQ = doc(this.db, 'AppSetting', 'CurrentSeason');
    let seasonDoc = await getDoc(seasonQ);

    let season = '';

    if (seasonDoc.exists() && seasonDoc.data().TextValue__c) {
      season = JSON.parse(seasonDoc.data().TextValue__c)?.Id;
    }

    global.season = season;

    return season;
  }

  async getFocusedExtractTransactions(cpfCnpj, type) {
    if (!cpfCnpj || !type) {
      return false;
    }

    const salesUrl = await this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_EXTRACTRANSACTIONS_URL, false);
    console.log(salesUrl);

    try {
      const response = await fetch(salesUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cpfCnpj: cpfCnpj, type: type })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async getFocusedAnalystRelantionshipTree(FirebaseId) {
    if (!FirebaseId) {
      return false;
    }

    const salesUrl = await this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_RELATIONSHIP_URL, false);

    try {
      const response = await fetch(salesUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: FirebaseId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data)) || [];
      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async getFocusedAnalystAccepted(FirebaseId) {
    if (!FirebaseId) {
      return false;
    }

    const salesUrl = await this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_ACCEPTED_URL, false);
    console.log(salesUrl);

    try {
      const response = await fetch(salesUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cpf: FirebaseId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async getFocusedAnalystData(cpf) {
    if (!cpf) {
      return false;
    }
    const salesUrl = await this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_CUSTOMERS_URL, false);
    try {
      const response = await fetch(salesUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cpf: cpf })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      return false;
    }
  }

  async getFocusedAnalystConsumptionData(cpf) {
    if (!cpf) {
      return false;
    }
    const salesUrl = await this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_CONSUPTION_URL, false);
    try {
      const response = await fetch(salesUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cpf: cpf })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      return false;
    }
  }

  async getRewardFilters() {
    let filtersQ = doc(this.db, 'AppSetting', 'FilterProductOptions');
    let filtersDoc = await getDoc(filtersQ);

    let filters = [];

    if (filtersDoc?.exists() && filtersDoc?.data()['TextValue__c']) {
      filters = JSON.parse(filtersDoc.data()['TextValue__c']);
    }

    return filters;
  }

  async getCaseTypes(callback) {
    let user = this.userHelper.getAuthUser();

    let casesQ, casesDoc;

    if (!user) {
      casesQ = doc(this.db, 'AppSetting', 'CaseSettingsUnlogged');
      casesDoc = await getDoc(casesQ);
    }

    if (!casesDoc?.exists()) {
      casesQ = doc(this.db, 'AppSetting', 'CaseSettings');
      casesDoc = await getDoc(casesQ);
    }

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      let objTextValue = JSON.parse(casesDoc.data()['TextValue__c']);
      if (objTextValue.types) {
        callback(objTextValue.types);
        return true;
      }
    }

    callback([]);
    return false;
  }

  async getCaseRecordTypeId() {
    let casesQ = doc(this.db, 'AppSetting', 'CaseSettings');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      let objTextValue = JSON.parse(casesDoc.data()['TextValue__c']);
      return objTextValue.recordType;
    }

    return false;
  }

  async uploadImage(img, path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    const snapshot = await uploadBytes(storageRef, img, metadata);
    const url = await getDownloadURL(storageRef);

    return url;
  }

  async deleteImage(path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);

    try {
      await deleteObject(storageRef);
      return true;
    }
    catch (e) {
      console.log('erro deletando imagem!', e);
      return false;
    }
  }

  async getAccountByCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    let accountQ = query(collection(this.db, 'Account'), where('acg_TaxId__c', '==', cnpj));
    let cnpjDoc = await getDocs(accountQ);

    let data = this.docsToArray(cnpjDoc);

    if (data.length)
      return data[0]

    return false;
  }

  // Uma conta raíz é aquela que possui os 8 primeiros dígitos iguais às suas contas filhas
  // e possui ParentIDCRM__c === null (ou sequer possui tal parâmetro)
  // Além disso, a conta deve ser elegível para registrar NFs (acg_ElegivelNF__c)
  async getParentAccountByCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    let rootCnpj = cnpj.slice(0, 8);
    let accountQ = query(
      collection(this.db, 'Account'),
      where('acg_TaxIdStarted__c', '==', rootCnpj),
      where('acg_ElegivelNF__c', '==', true)
    );
    let cnpjDoc = await getDocs(accountQ);

    let data = this.docsToArray(cnpjDoc);

    if (data.length) {
      let result = false;

      data.map(item => {
        if (!item.ParentIDCRM__c) {
          result = item;
        }
      })
      console.log('result', result)
      return result;
    }

    return false;
  }

  async getTermVersion(id) {
    let termQ = doc(this.db, 'AcceptanceTermVersion', id)
    let termDoc = await getDoc(termQ);

    if (termDoc?.exists()) {
      let termData = termDoc.data();

      if (termData.acg_IsActive__c) {
        return termData;
      }
    }

    return false;
  }

  parseTermsForCheckbox(terms, termVersionPath) {
    let parsedTerms = terms?.map(term => {
      let parsedTerm = { ...term };
      let label = 'Aceito os termos de uso';

      if (term.acg_AcceptanceTerm__r?.acg_Label__c) {
        label = term.acg_AcceptanceTerm__r?.acg_Label__c;
        let hashcount = (label.match(/##/g) || []).length;
        if (hashcount === 2) {
          let link = termVersionPath.replace(':id', term.Id.slice(0, 15))
          label = label.replace(/##/, '<a href="' + link + '" target="_blank">')
          label = label.replace(/##/, '</a>')
        }
      }

      parsedTerm.input = {
        label,
        error: term?.errorMessage || '',
      }

      return parsedTerm;
    })

    return parsedTerms
  }

  async getInvoiceSyngentaTotal(invoiceId) {
    if (!invoiceId) {
      return false;
    }

    let invoiceUrl = await this.getHerokuUrl(HEROKU_INVOICE_SYNGENTA_TOTAL_URL);

    let data = {
      invoiceId,
    }

    try {
      let response = await fetch(invoiceUrl + '?' + new URLSearchParams(data), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
      });

      if (response.ok) {
        let result = await response.json();

        if (result?.success) {
          return result.total;
        }
      }
    }
    catch (e) {
      console.log('e', e)
    }

    return 0;
  }

  async getAgrolinkData(params) {
    let agrolinkUrl = await this.getHerokuUrl(HEROKU_AGROLINK_URL, false);

    try {
      let response = await fetch(agrolinkUrl + '?' + new URLSearchParams(params), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
      });

      if (response.ok) {
        let result = await response.json();

        if (result?.success) {
          return result.data;
        }
      }
    }
    catch (e) {
      console.log('e', e)
    }

    return false;
  }

  async saveLog({ method, data, result }) {
    const removeEmpty = (obj) => {
      let newObj = {};
      Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
        else if (obj[key] !== undefined) newObj[key] = obj[key];
      });
      return newObj;
    };

    let log = {
      date: serverTimestamp(),
      source: 'site',
      currentUrl: window.location.href,
      method,
      data,
      result: result?.message ? result.message : (JSON.stringify(result) ? JSON.stringify(result) : result),
    }

    log = removeEmpty(log);

    await addDoc(collection(this.db, 'Logs'), log)
  }

  dateDisplayFormat(date) {
    if (date?.length && date?.search('-') !== -1) {
      return date.split('-').reverse().join('/')
    }

    return date;
  }

  appScreenToUrl(routes, appScreen) {
    switch (appScreen) {
      case 'Profile':
        return routes.profile.path;
      case 'Survey':
        return routes.survey.path;
      case 'ReceiptHistory':
        return routes.home.path;
      default:
        return routes.home.path;
    }
  }

  nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  docsToArray(elDocs) {
    let data = [];

    if (elDocs.docs.length) {
      data = elDocs.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }

  async getRafflesProdutividadeIds() {
    let casesQ = doc(this.db, 'AppSetting', 'RafflesProdutividade');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      return casesDoc.data()['TextValue__c']
    }

    return false;
  }

  async getRafflesProdutividadeConsultorIds() {
    let casesQ = doc(this.db, 'AppSetting', 'RafflesProdutividadeConsultor');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      return casesDoc.data()['TextValue__c']
    }

    return false;
  }

  async getConsultantPerformance(pointId) {
    if (!pointId) {
      return false;
    }

    const performanceUrl = await this.getHerokuUrl(`${HEROKU_GET_PERFORMANCE_URL}Consultant`, false);
    try {
      const response = await fetch(performanceUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pointId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      return false;
    }
  }

  async getBalance(idContact) {
    if (!idContact) {
      return false;
    }

    const balanceUrl = await this.getHerokuUrl(`${HEROKU_GET_BALANCE_TRANSFER_POINTS_URL}/${idContact}`, false);

    try {
      let response = await fetch(balanceUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
      });

      if (response.ok) {
        const parsedResponse = await response.json();
        return parsedResponse?.Balance;
      } else {
        return { message: 'erro ao consultar saldo do usuário!', error: true }
      };
    }
    catch (e) {
      console.log('error', JSON.stringify(e));
      return false;
    };
  };

  userByDoc = async (doc) => {
    if (!doc) {
      return false;
    }

    const getUserUrl = await this.getHerokuUrl(`${HEROKU_GET_USER_BY_DOC}/${doc}`, false);

    try {
      let response = await fetch(getUserUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
      });

      if (response.ok) {
        const parsedResponse = await response.json();
        return parsedResponse;
      } else {
        return { message: 'erro ao trazer usuário!', error: true }
      };
    }
    catch (e) {
      console.log('error', JSON.stringify(e));
      return false;
    };
  }

  completedTransfer = async (payload) => {
    const completedUrl = await this.getHerokuUrl(HEROKU_POST_COMPLETED_TRANSFER, false);

    try {
      const response = await fetch(completedUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...payload })
      });

      if (response?.ok) {
        const result = await response.json();
        return { success: true, message: result?.message };
      } else {
        return { success: false, message: 'algo deu errado com parse!' }
      }
    } catch (e) {
      return false;
    }
  }

  async getFavoriteAccounts(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const favoriteAccountsUrl = await this.getHerokuUrl(`${HEROKU_FAVORITE_ACCOUNTS}/${firebaseId}`, false);
    try {
      const response = await fetch(favoriteAccountsUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      return false;
    }
  }

  async validatePointGroupUser(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const validateUserUrl = await this.getHerokuUrl(`${HEROKU_VALIDATE_USER}/${firebaseId}`, false);
    try {
      const response = await fetch(validateUserUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false;
    }
  }

  async createPointGroup(groupData) {
    const createPointGroupUrl = await this.getHerokuUrl(HEROKU_CREATE_POINT_GROUP, false);
    try {
      const response = await fetch(createPointGroupUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...groupData }),
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      return false;
    }
  }

  async addAndInvite(leaderFirebaseId, participantFirebaseId, entryRequest = false) {
    if (!leaderFirebaseId || !participantFirebaseId) {
      return false;
    }

    const addAndInviteUrl = await this.getHerokuUrl(HEROKU_ADD_AND_INVITE, false);
    try {
      const response = await fetch(addAndInviteUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...(entryRequest && { 'Type': 'entry-request' }),
        },
        body: JSON.stringify({ leaderFirebaseId, participantFirebaseId }),
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async isInAGroup(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const isInAGroupUrl = await this.getHerokuUrl(`${HEROKU_IS_IN_A_GROUP}/${firebaseId}`, false);
    try {
      const response = await fetch(isInAGroupUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getInvites(firebaseId, type = "Group") {
    if (!firebaseId) {
      return false;
    }

    const getInvitesUrl = await this.getHerokuUrl(`${HEROKU_GET_INVITES}/${firebaseId}`, false);
    try {
      const response = await fetch(getInvitesUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Type': type,
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async acceptInvite(firebaseId, inviteId, type) {
    if (!firebaseId || !inviteId || !type) {
      return false;
    }

    const acceptInviteUrl = await this.getHerokuUrl(HEROKU_ACCEPT_INVITE, false);
    try {
      const response = await fetch(acceptInviteUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Type': type
        },
        body: JSON.stringify({ firebaseId, inviteId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async rejectInvite(firebaseId, inviteId, type) {
    if (!firebaseId || !inviteId || !type) {
      return false;
    }
    const rejectInviteUrl = await this.getHerokuUrl(HEROKU_REJECT_INVITE, false);
    try {
      const response = await fetch(rejectInviteUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Type': type,
        },
        body: JSON.stringify({ firebaseId, inviteId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async searchContact(leaderFirebaseId, participantCpfCnpj) {
    if (!leaderFirebaseId || !participantCpfCnpj) {
      return false;
    }

    const searchContactUrl = await this.getHerokuUrl(`${HEROKU_SEARCH_CONTACT}/${leaderFirebaseId}/${participantCpfCnpj}`, false);
    try {
      const response = await fetch(searchContactUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getGroupData(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const groupDataUrl = await this.getHerokuUrl(`${HEROKU_GET_GROUP_DATA}/${firebaseId}`, false);
    try {
      const response = await fetch(groupDataUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async inviteContact(leaderFirebaseId, participantFirebaseId, type) {
    if (!leaderFirebaseId || !participantFirebaseId || !type) {
      return false;
    }

    const inviteContactUrl = await this.getHerokuUrl(HEROKU_INVITE_CONTACT, false);
    try {
      const response = await fetch(inviteContactUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Type': type,
        },
        body: JSON.stringify({ leaderFirebaseId, participantFirebaseId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async changeGroupName(name, id) {
    if (!name || !id) {
      return false;
    }

    const changeNameUrl = await this.getHerokuUrl(HEROKU_CHANGE_GROUP_NAME, false);
    try {
      const response = await fetch(changeNameUrl, {
        method: 'PUT',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, id })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async leaveGroup(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const leaveGroupUrl = await this.getHerokuUrl(`${HEROKU_LEAVE_GROUP}/${firebaseId}`, false);
    try {
      const response = await fetch(leaveGroupUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async removeFromGroup(leaderFirebaseId, participantFirebaseId) {
    if (!leaderFirebaseId || !participantFirebaseId) {
      return false;
    }

    const removeFromGroupUrl = await this.getHerokuUrl(HEROKU_REMOVE_FROM_GROUP, false);
    try {
      const response = await fetch(removeFromGroupUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ leaderFirebaseId, participantFirebaseId }),
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async endGroup(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const endGroupUrl = await this.getHerokuUrl(`${HEROKU_END_GROUP}/${firebaseId}`, false);
    try {
      const response = await fetch(endGroupUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async promoteToLeader(leaderFirebaseId, participantFirebaseId) {
    if (!leaderFirebaseId || !participantFirebaseId) {
      return false;
    }

    const promoteToLeaderUrl = await this.getHerokuUrl(HEROKU_PROMOTE_TO_LEADER, false);
    try {
      const response = await fetch(promoteToLeaderUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ leaderFirebaseId, participantFirebaseId }),
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getRegulations(userId) {
    if (!userId) {
      return false;
    };

    const groupDataUrl = await this.getHerokuUrl(`${HEROKU_GET_REGULATIONS}/${userId}`, false);
    try {
      const response = await fetch(groupDataUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getRecordTypes(developerName = 'WelcomeContact') {
    const recordTypeUrl = await this.getHerokuUrl(`${HEROKU_GET_RECORD_TYPE}/${developerName}`, false);
    try {
      const response = await fetch(recordTypeUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getProfilesByUserGroup(userGroupId) {
    if (!userGroupId) {
      return false;
    }

    const getProfilesByUserGroupUrl = await this.getHerokuUrl(`${HEROKU_GET_PROFILES_BY_USER_GROUP}/${userGroupId}`, false);

    try {
      const response = await fetch(getProfilesByUserGroupUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async hasActiveProfiles(userGroupId) {
    if (!userGroupId) {
      return false;
    }

    const profiles = await this.getProfilesByUserGroup(userGroupId);

    if (profiles && profiles?.length) {
      return profiles.some((u) => !["Inativo", "Blocked"].includes(u.acg_Status__c))
    }

    return false;
  }

  async getPerformanceSalesTeam(status, channel, businessCycleId) {
    if (!status || !channel || !businessCycleId) {
      return false;
    }
    const { url: herokuApi, token } = await this.getHerokuDomainApi();
    const url = `${HEROKU_GET_PERFORMANCE_SALES_TEAM_URL}${status === "Pontuação liberada" ? "_aprovado?" : "?"}p_canal=${channel}&p_business_cycle_id=${businessCycleId}`;
    const performanceSalesTeamUrl = `${herokuApi}${url}`

    try {
      const response = await fetch(performanceSalesTeamUrl, {
        method: 'GET',
        headers: {
          'Authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getPerformanceSalesTeamAnticipation(status, anticipationId) {
    if (!status || !anticipationId) {
      return false;
    }

    const { url: herokuApi, token } = await this.getHerokuDomainApi();
    const url = `${HEROKU_GET_PERFORMANCE_SALES_TEAM_ANTICIPATION_URL}${status === "Aprovado" ? "_aprovado?" : "?"}p_anticipation_id=${anticipationId}`
    const performanceSalesTeamAnticipationUrl = `${herokuApi}${url}`

    try {
      const response = await fetch(performanceSalesTeamAnticipationUrl, {
        method: 'GET',
        headers: {
          'Authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getDistributors(limit, page) {
    const distributorUrl = await this.getHerokuUrl(`${HEROKU_GET_DISTRIBUTORS_URL}/noInvoce?limit=${limit}&page=${page}`, true);
    try {
      const response = await fetch(distributorUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (e) {
      return false;
    }
  }

  async getCanaisSolicitantes() {
    const getCanaisUrl = await this.getHerokuUrl(`${HEROKU_GET_DISTRIBUTORS_URL}/isInvoice`);

    try {
      const response = await fetch(getCanaisUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getUserInfoBeforeLogin(firebaseId) {
    if (!firebaseId) {
      return false;
    }

    const getUserInfoBeforeLoginUrl = await this.getHerokuUrl(`${HEROKU_GET_USER_INFO_BEFORE_LOGIN}/${firebaseId}`, false);

    try {
      const response = await fetch(getUserInfoBeforeLoginUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async removeFavoriteAccount(mainContactFirebaseId, participantFirebaseId) {
    if (!mainContactFirebaseId || !participantFirebaseId) {
      return false;
    }

    const removeFavoriteAccountUrl = await this.getHerokuUrl(HEROKU_REMOVE_FAVORITE_ACCOUNT, false);
    try {
      const response = await fetch(removeFavoriteAccountUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mainContactFirebaseId, participantFirebaseId })
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async snapStartUpMessages() {
    let elQ = query(collection(this.db, 'StartUpMessages'), where('Active__c', '==', true), orderBy('Priority__c', 'asc'));
    let elDoc = await getDocs(elQ);

    let data = [];

    if (elDoc.docs.length) {
      data = elDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return { data };
  }

  async groupHistory(inviteId) {
    if (!inviteId) {
      return false;
    }
    const groupHistoryUrl = await this.getHerokuUrl(`${HEROKU_GROUP_HISTORY}/${inviteId}`, false);
    try {
      const response = await fetch(groupHistoryUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getCampaigns(firebaseId) {
    const campaignsUrl = await this.getHerokuUrl(`${HEROKU_CAMPAIGNS}/${firebaseId}`, false);
    // const campaignsUrl = await this.getHerokuUrl(`${HEROKU_CAMPAIGNS}/O4SgDWxLPPxXHilyU0T9`, false);

    try {
      const response = await fetch(campaignsUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async enrollCampaign(campaignId, firebaseId) {
    const campaignenrollmentUrl = await this.getHerokuUrl(`${HEROKU_CAMPAIGNS_ENROLLMENT}/${campaignId}/${firebaseId}`, false);

    try {
      const response = await fetch(campaignenrollmentUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getCampaignsResults(campaignId, firebaseId) {
    const campaignsResultsUrl = await this.getHerokuUrl(`${HEROKU_CAMPAIGNS_RESULTS}/${campaignId}/${firebaseId}`, false);
    try {
      const response = await fetch(campaignsResultsUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getCampaignsMyNumbers(campaignId, firebaseId) {
    const campaignsMyNumbersUrl = await this.getHerokuUrl(`${HEROKU_CAMPAIGNS_MY_NUMBERS}/${campaignId}/${firebaseId}`, false);
    try {
      const response = await fetch(campaignsMyNumbersUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getAboutProgramSweepstake() {
    let Q = query(collection(this.db, 'Sweepstake'));
    let QDocs = await getDocs(Q);

    let data = [];

    if (QDocs.docs.length) {
      data = QDocs.docs.map((doc) => doc.data());
    }

    return data;
  }

  async getAboutProgramData() {
    let Q = doc(this.db, 'AppSetting', "AboutProgram");
    let QDoc = await getDoc(Q);

    const docs = await this.getAboutProgramSweepstake();

    const data = {};

    if (QDoc.exists()) {
      const { HtmlValue__c, TextValue__c } = QDoc.data();
      data.html = HtmlValue__c;
      data.image = TextValue__c;
      data.docs = docs
    }

    return data;
  }

  async validateSweepstake(id, docs) {
    const response = await this.verifySweepstakeIds(id, docs);

    const validDocs = [];

    if (response?.length > 0) {
      response.forEach((response) => {
        if (response.valid) {
          const doc = docs.find((d) => d.Id === response.value);
          validDocs.push({ ...doc, valid: true })
        }
      });

      return validDocs.sort((a, b) => a?.Order__c - b?.Order__c);
    }

    return docs.sort((a, b) => a?.Order__c - b?.Order__c);;
  }

  async verifySweepstakeIds(id, docs) {
    const verifySweepstakeIdsUrl = await this.getHerokuUrl(HEROKU_VERIFY_SWEEPSTAKE_IDS, false);

    const body = {
      objectName: "Product2",
      targetGroupField: "acg_TargetGroup__c",
      filterField: "Id",
      contactId: id,
      messageField: "",
      items: docs?.map((d) => ({ value: d.Id }))
    }

    try {
      const response = await fetch(verifySweepstakeIdsUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return []
    }
  }

  async getGenericInfo(objectName, filterField, contactFieldValue, contactField, target, messageField) {
    const body = messageField
      ? JSON.stringify({ objectName, filterField, contactFieldValue, contactField, target, messageField })
      : JSON.stringify({ objectName, filterField, contactFieldValue, contactField, target })

    const genericInfoUrl = await this.getHerokuUrl(`${HEROKU_GENERIC_INFO}`, false);
    try {
      const response = await fetch(genericInfoUrl, {
        method: 'POST',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: body
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getUserByReferralCode(code) {
    const url = await this.getHerokuUrl(`${HEROKU_GET_USER_REFERRAL_CODE}/${code}`, false);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result;
    } catch (error) {
      return false
    }
  }

  async getConsultantBalance(cpfCnpj) {
    if (!cpfCnpj) {
      return false;
    }

    const consultantBalanceUrl = await this.getHerokuUrl(`${HEROKU_GET_CONSULTANT_BALANCE}/${cpfCnpj}`, false);

    try {
      const response = await fetch(consultantBalanceUrl, {
        method: 'GET',
        headers: {
          'Authorization': HEROKU_AUTHORIZATION,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      const result = JSON.parse(JSON.stringify(data));
      return result.balance || 0;
    } catch (error) {
      return 0
    }
  };
}