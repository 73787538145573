import { lazy, useState } from "react";
import MoneyText from "../Atoms/MoneyText";
import Point from "../Atoms/Point";
import { PauseCircle, XCircle, CheckCircle, ChevronUp, ChevronDown } from 'react-feather';

const ToolHelper = {
  usePopup: () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ content, setContent ] = useState('');
    const [ themeLayout, setThemeLayout ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
  
    return {
      isOpen,
      setIsOpen,
      isLoading,
      setIsLoading,
      content,
      setContent,
      themeLayout,
      setThemeLayout,
    }
  },
  useRightPanel: () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ topCloseButton, setTopCloseButton ] = useState('');
    const [ content, setContent ] = useState('');
    const [ maxWidth, setMaxWidth ] = useState(380);
    const [ themeLayout, setThemeLayout ] = useState({});
  
    return {
      isOpen,
      setIsOpen,
      topCloseButton,
      setTopCloseButton,
      content,
      setContent,
      themeLayout,
      setThemeLayout,
      maxWidth,
      setMaxWidth,
    }
  },
  // deprecated -- usar getThemedConfig
  getTheme: (props,customPreset,defaultPreset = 'default') => {
    let preset = customPreset;
    let theme = props.config.presets[preset];
    let defaultTheme = props.config.presets[defaultPreset];

    theme = {...defaultTheme, ...theme, ...props.theme};

    return theme;
  },
  getThemedConfig: (props,presetPath,defaultPreset = 'default') => {
    let themeLayout = {};
    if(presetPath){
      let aPresetPath = presetPath.split('.');
      let calculatedPreset = props.config.theme;
      aPresetPath.map((path) => {
        calculatedPreset = calculatedPreset[path] || {}
      });
      themeLayout = props.config.presets[calculatedPreset];
    }

    let defaultTheme = props.config.presets[defaultPreset];
    themeLayout = {...defaultTheme, ...themeLayout, ...props.theme};

    return {...props.config, themeLayout};
  },
  // Faz as substituições padrões de marcadores e possíveis extras
  fillTextPlaceholder: (text,config,extra = []) => {
    if(typeof text !== 'string'){
      return '';
    }

    // Trocas padrões
    extra.push({find:'{POINTS_LABEL}',replace:config?.strings?.POINTS});
    extra.push({find:'{LEVEL_LABEL}',replace:config?.strings?.LEVEL});

    let result = text;

    extra.forEach((sub) => {
      if(result.includes(sub.find)){
        // Se a string possui o placeholder mas não tem a troca definida, a string será apagada
        if(sub.replace === undefined || sub.replace === false){
          result = '';
        }
        else{
          result = result.replaceAll(sub.find,sub.replace)
        }
      }
    })

    return result;
  },
  // Remove tags HTML de uma string
  stripTags: (html) => {
    var tmp = document.createElement('div');
    tmp.innerHTML = html;
    
    return tmp.textContent || tmp.innerText;
  },
  // Trunca uma string e adiciona elipse, caso necessário
  truncate: (string,size) => {
    return (string.length > size) ? string.substr(0, size-1) + '&hellip;' : string;
  },
  // Inclui um JS no corpo do HTML
  includeJS: (path,callback) => {
    const script = document.createElement("script");
    if(callback){
      script.onload = callback;
    }
    script.src = path;
    script.async = true;
    document.body.appendChild(script);
  },
  // Inclui um CSS no corpo do HTML
  includeCSS: (path) => {
    const css = document.createElement("link");
    css.rel = 'stylesheet';
    css.type = 'text/css';
    css.href = path;
    document.body.appendChild(css);
  },
  // Avalia uma variável de tamanho e insere "px" quando necessário para simular o comportamento do react CSS
  fixSizeUnit: (size) => {
    return typeof size === 'number' ? size + 'px' : size;
  },
  // Recebe um objeto e retorna o valor relacionado ao tipo de usuário. Por padrão retorna "default"
  getOptionByUserType(optionsByType){
    if(global.userType && optionsByType[global.userType]){
      return optionsByType[global.userType];
    }

    return optionsByType.default;
  },
  checkProfile: ( profileCodes, history ) => {
    const doLogout = profileCodes?.includes("timedevendas") || profileCodes?.includes("syngenta");
    if (!doLogout)
      history.push('/logout')
  },
  // Recebe o caminho dos arquivos e procura o arquivo com o mesmo nome do tipo do usuário. Por padrão retorna o arquivo "default.js"
  importByUserType: async (path,getConfigFile) => {
    // Padronizando path com / no fim
    if(path.slice(path.length-1) !== '/'){
      path += '/';
    }

    // Colocar alguma coisa no fim do nome do arquivo (no caso, "Config") 
    // foi a única forma que encontrei para o import funcionar adequadamente.
    // O compilador tenta limitar a liberdade de importar qualquer arquivo e impõe restrições
    // Aparentemente não é possível ter variáveis perto do começo ou do fim do caminho do arquivo
    // "defaultConfig" poderia ser só "default", mas decidi manter padronizado
    let importedFile = await getConfigFile(`${path}defaultConfig.js`);

    let result = {};

    result.default = importedFile.default;

    if(global.userTypes){
      for(let i in global.userTypes){
        let customImportedFile = await getConfigFile(`${path}${global.userTypes[i]}Config.js`);

        if(customImportedFile){
          result.default = {
            ...importedFile.default,
            ...customImportedFile.default,
          }
          break;
        }
      }
    } else if(global.userType){
      let customImportedFile = await getConfigFile(`${path}${global.userType}Config.js`);

      if(customImportedFile){
        result.default = {
          ...importedFile.default,
          ...customImportedFile.default,
        }
      }
    }

    return result
  },
  // Retorna os 3 primeiros caracteres do e-mail e o domínio
  getEmailHint: (phrase,email) => {
    if(
      phrase.includes('{EMAIL_HINT}') &&
      typeof email === 'string' &&
      email.includes('@')
    ){
      let emailHint = email.split('@')[0].slice(0,3) + '***@' + email.split('@')[1];
      return phrase.replace('{EMAIL_HINT}',emailHint);
    }

    return '';
  },
  // Retorna os 4 últimos dígitos do telefone
  getPhoneHint: (phrase,phone) => {
    if(
      phrase.includes('{PHONE_HINT}') &&
      typeof phone === 'string' && 
      phone.length > 4
    ){
      let phoneHint = phone.slice(-4);
      return phrase.replace('{PHONE_HINT}',phoneHint);
    }

    return '';
  },
  getCurrentTime: async () => {
    let response = await fetch('//worldtimeapi.org/api/timezone/America/Sao_Paulo', {
      headers: {
        Accept: 'application/json',
      },
    });
    
    if (response.ok) {
      let jresponse = await response.json();

      if(jresponse.datetime){
        return jresponse.datetime;
      }
    }

    return false;
  },
  getDigits: (string) => {
    return string?.replace(/\D/g, "") || ""
  },
  maskText: (text,mask) => {
    if(text?.length){
      switch(mask){
        case 'cnpj':
          return text.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
    }

    return text;
  },
  isCNPJValid: (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj === '') return false;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" || 
      cnpj === "11111111111111" || 
      cnpj === "22222222222222" || 
      cnpj === "33333333333333" || 
      cnpj === "44444444444444" || 
      cnpj === "55555555555555" || 
      cnpj === "66666666666666" || 
      cnpj === "77777777777777" || 
      cnpj === "88888888888888" || 
      cnpj === "99999999999999"
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado !== parseInt(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado !== parseInt(digitos.charAt(1)))
          return false;
           
    return true;
  },
  formatDataByType(value,type,options = {}){
    let result;
    let { config } = options;

    switch(type){
      case 'point':
        result = <Point config={config} qty={value} small />
      break;
      case 'cpf':
        result = value?.replace(/\D/g,'').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      break;
      case 'cnpj':
        result = value?.replace(/\D/g,'').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      break;
      case 'money':
        result = <MoneyText config={config} value={value} />
      break;
      case 'date':
        result = value ? new Date(value).toLocaleDateString('pt-BR',{timeZone: 'WET'}) : ''
      break;
      case 'date--monthyear':
        result = new Date(value).toLocaleDateString('pt-BR',{timeZone: 'WET',month:'short',year:'numeric'})
      break;
      case 'float':
        result = (value ? +value : 0).toLocaleString()
      break;
      case 'text': default:
        result = value;
      break;
    }

    return result;
  },
  invoiceStatusProperties(status,config){
    switch (status) {
      case 'Em Análise':
      case 'Em Reanálise':
        return {
          iconList: <PauseCircle width={'2.2em'} height={'2.2em'}/>,
          iconDetail: <PauseCircle strokeWidth={3}/>,
          statusColor: config.themeLayout?.warning,
        };
      case 'Reprovada':
        return {
          iconList: <XCircle width={'2.2em'} height={'2.2em'}/>,
          iconDetail: <XCircle strokeWidth={3}/>,
          statusColor: config.themeLayout?.error,
        };
      case 'Aprovada':
      default:
        return {
          iconList: <CheckCircle width={'2.2em'} height={'2.2em'}/>,
          iconDetail: <CheckCircle strokeWidth={3}/>,
          statusColor: config.themeLayout?.success,
        };
    }
  },
  getRouteByLocation(location,routes){
    const path = location.pathname;
    let route;
    let splitPath = path.split('/');

    Object.entries(routes).map(([key,value]) => {
      // Os caminhos podem ser idênticos...
      if(value.path === path){
        route = value;
      }
      // ... ou serem uma composição com parâmetro
      // Ex: path       === /termos-de-uso/a567Z000000sr3a
      //     value.path === /termos-de-uso/:id
      else if(splitPath.length > 2){
        let valueSplitPath = value.path.split('/');

        if(valueSplitPath.length === splitPath.length){
          let matches = true;

          for(let i in splitPath){
            if(splitPath[i] !== valueSplitPath[i] && valueSplitPath[i][0] !== ':'){
              matches = false;
            }
          }

          if(matches){
            route = value;
          }
        }
      }
    })
    
    return route;
  },
  getMetaByRoute(route,metaConfig = {}){
    let finalMeta = {...metaConfig, ...route?.meta};

    if(route?.meta?.title && metaConfig?.title){
      finalMeta.title = route.meta.title + ' - ' + metaConfig.title;
    }

    return finalMeta;
  },
  isIOS(){
    const browserInfo = navigator.userAgent.toLowerCase();
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(platform)) {
      return true;
    } 
    return false;
  }
}

export default ToolHelper;