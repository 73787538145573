export const infoCard = [
  {
    label: 'Até 30 dias',
    value: 30,
    stateName: 'ateTres'
  },
  {
    label: 'Até 60 dias',
    value: 60,
    stateName: 'ateSeis'
  },
  {
    label: 'Até 90 dias',
    value: 90,
    stateName: 'ateNove'
  }
];

export const infoOrder = [
  {
    label: 'Data da ação',
    value: 'PointDate__c',
    defaultOrder: 'desc',
  },
  {
    label: 'Data da expiração',
    value: 'ExpirationDate__c',
    defaultOrder: 'desc',
  },
  {
    label: 'Ação',
    value: 'Action__c',
    defaultOrder: 'asc',
  },
  {
    label: 'Descrição',
    value: 'Description__c',
    defaultOrder: 'asc',
  },

  {
    label: 'Ganho comercial',
    value: 'Points__c',
    defaultOrder: 'desc',
  }
];

export const traducoes = {
  Reversal: 'Estornos',
  Canceled: 'Cancelados',
  Loan: 'Empréstimos',
  Bonus: 'Bônus',
  Earned: 'Pontuações',
  Redeem: 'Resgates',
  Expired: 'Expirações',
  Manual: 'Fornecidos',
  Debt: 'Débitos',
  Reserved: 'Reservados',
  Transfered: 'Transferência',
};

export const formatCPF = (str) => (
  str
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
);

export const formatCNPJ = str => (
  str
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
);


export const infoOrderSurvay = [
  {
    label: 'Título',
    value: '',
    defaultOrder: 'desc',
  },
  {
    label: 'Divisão',
    value: '',
    defaultOrder: 'desc',
  },
  {
    label: 'Data',
    value: '',
    defaultOrder: 'asc',
  },
  {
    label: 'Questões',
    value: '',
    defaultOrder: 'asc',
  },

  {
    label: 'Pontuação',
    value: '',
    defaultOrder: 'desc',
  }
];

export const validateSequencial = (value) => {
  const rg = value;

  let sequentials = 0;

  for (let i = 1; i < rg.length; i += 1) {
    if (Number(rg[i]) === Number(rg[0]) + i) {
      sequentials += 1;
    }
  }

  if (sequentials === rg.length - 1) {
    return false;
  }

  return true
};

export const validatePIS = (pis) => {
  pis = pis.replace(/\D/g, '');
  let digito = pis[pis.length - 1];

  if (pis.length !== 11) {
    return false;
  }

  const pesos = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(pis.charAt(i)) * pesos[i];
  }

  let resto = soma % 11;

  let resultado = 11 - resto;

  if (parseFloat(digito) === 0 && (parseFloat(resultado) === 10 || parseFloat(resultado) === 11)) {
    return true;
  } else if (parseFloat(resultado) === parseFloat(digito)) {
    return true
  } else {
    return false
  };
}

export const getWorksheetFormatedData = (data = []) => {
  const wsData = data.map(row => {
    const formattedRow = {};

    Object.keys(row).forEach(key => {
      const value = row[key];

      if (Array.isArray(value)) {
        formattedRow[key] = JSON.stringify(value);
      }
      else if (typeof value === 'number') {
        const numberFormat = Number.isInteger(value) ? "0" : "0.0"
        formattedRow[key] = { v: value, t: "n", z: numberFormat };
      } else {
        formattedRow[key] = value;
      }
    });

    return formattedRow;
  });

  return wsData;
}
